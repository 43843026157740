const Label = ({
  variant = "default",
  fontFamily = "Times, serif",
  labelWidth,
  name,
  label,
  showSeparator = true,
  lablefontsize
}) => {
  return (
    <label
      className="input__label"
      style={{
        width: `${labelWidth}px`,
        // fontSize: variant === "default" ? "12.1px" : "10.1px",
        fontSize:`${lablefontsize}px`,
        fontFamily,
      }}
      htmlFor={name}>
      <span>{label}</span>
      {showSeparator && <span className="input__separator">:</span>}
    </label>
  );
};

export default Label;
