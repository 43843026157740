import { useEffect, useRef, useState } from "react";

const DynamicTextArea = ({ value: initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue);
  const [fontSize, setFontSize] = useState(12.1);
  const ref = useRef();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const { scrollHeight } = ref.current;

    if (scrollHeight > 209) {
      setFontSize(fontSize - 0.2);
    }
  }, [value, fontSize]);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value); // Call the prop function to update the parent state
    }
  };

  return (
    <textarea
      ref={ref}
      value={value}
      style={{ fontSize: `${fontSize}px`,height:"190px" }}

      name="findings"
      onChange={handleChange}
      className="content"
    />
  );
};

export default DynamicTextArea;
